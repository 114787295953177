/*
---
name: .page-fluid
category: Page/Fluid
tag:
 - responsive
compile: true
---

`.page-fluid` centers the page content and adds a padding so the page sticks not to the browser viewport.
Furthermore it has a max-width based on the last `@grid-break-points` value.

```jade
div.page-fluid
	div.aigis-debug Some page content
```

## Max-width page

To broaden the width of the page you can add the modifier `.-unlimited`. Thsi will span the page to the full width.

```jade
div.page-fluid.-unlimited
	div.aigis-debug Some page content
```
*/
.page-fluid {

	@max-width-point: extract( @grid-break-points, length( @grid-break-points ) );
	
	max-width: @max-width-point + 2 * extract( @page-padding, 2 );
	margin: auto;
	padding: @page-padding;

	&.-unlimited {
		max-width: none;
	}
}
