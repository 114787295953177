.delimiter-line {
    width: 100%;
    border-bottom: 6px solid @brand-color;
}


@media only screen and (max-width: @small-break-point) {

    .delimiter-line {
        border-bottom: 4px solid @brand-color;
        
    }
}