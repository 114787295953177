.answer-view {

    &.-wrong {
        color: red;
    }
    .label {
        margin-left: rem(15px);
        padding-left: rem(10px);
        position:relative;
        display:block;
        >.input {
            top: rem(11px);
            position: absolute;
            left: rem(-15px);
            margin: 0;
        }


    }

    .answertext {
        // display: inline;
        // margin-left: rem(15px);
    }
}