/*
---
name: .media-responsive
category: Layout/Media
tag:
 - responsive
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.stretch` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.stretch(src="https://www.youtube.com/watch?v=TCd6PfxOy0Y")
```
*/
.media-responsive {
	width: 100%;
	position: relative;
	padding-bottom: 50%;

	&.-media-4-3 {
		padding-bottom: 75%;
	}

	&.-media-16-9 {
		padding-bottom: 56.26%
	}
}
