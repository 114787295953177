h1 {
    font-size: rem(64px);
    letter-spacing: rem(1.9px);
    line-height: rem(70px);
    font-weight:normal;
}

h2 {
    font-size: rem(48px);    
    line-height: rem(58px);
    font-weight:normal;
}

h3 {
    line-height: rem(27px);
    font-size: rem(20px);
    font-weight: bold;
}

//test
@media only screen and (max-width: @small-break-point) { 
     
    h2 {
        font-size: rem(26px);    
        line-height: rem(34px);
    }

    
    h3 {
        line-height: rem(25px);
        font-size: rem(18px);
     }
 }