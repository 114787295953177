.page-footer {
    width: 100%;
    background-color:  @brand-color;
    margin-top: 200px;
    height: 80px;
    display: flex;
    align-items: center;

    .text {
        color: #fff;
        margin-left: 32px;
        .impressum {
            color: #fff;
        }
    }

}


@media only screen and (max-width: @small-break-point) { 
    .page-footer {
        margin-top: 160px;
    }
}


