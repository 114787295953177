.filter-container {
    position: fixed;
    right: 40px;
    bottom: 0px;
    z-index:10;
    box-shadow: 0.1px 0.1px 16px #6a6a6a54;
}


@media only screen and (max-width: @small-break-point) {
    .filter-container {
        right: 16px;
    }
}