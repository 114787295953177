/*
---
name: .button-default
category: Buttons/Default
tag:
 - component
 - button
---

The default button sets some basic properties so it is working as a `button` or `a` tag.

An example for a button:
```jade
button.button-default My Button
```

An example for a link:
```jade
a.button-default My Link
```
*/
.button-default {
	appearance: none;
	background: transparent;
	border: none;
	display: inline-block;
	cursor: pointer;
	outline: none;
}