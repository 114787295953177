.lightbox {
    .container {
        position: relative;
        display: inline-block;
        

        // .image {
        //     width: auto;
        //     max-height: 300px;
        //     margin: 0 auto;
        //     display: block;   
        // }

        // .caption-element {
        //     text-align:center;
        // }
        
        .open {
            position: absolute; 
            bottom: 8px;
            right:0px;
            width: 32px;
            height: 32px;
            transform: translate(-50%, -50%);
        }
    }

    min-width:100%;
    .open, .close {
        cursor: pointer;
    }

    .open {
        display: block;
    }
    .close {
        display:none;
    }

    
    &.-open {
        position: fixed;
        height: 100%;
        z-index: 100;
        top: 0px;
        left:0px;
        background-color: rgba(0, 0, 0, 0.8);
        overflow-y: scroll;

        .caption-element {
            display: none;
        }
        .container {
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            max-height: 100%;
            .inner {
                display: inline-block;
                position: relative;
                margin: 20px 0px;
            }
        }
        
        

        .close {
            display: block;
            position: absolute;
            right: 16px;
            top: 16px;
            width: 32px;
            height: 32px;
        }
        .open {
            display: none;
        }

        .caption {
            text-align: center;
            margin-bottom: 16px;
        }
    }
}