.feedback-panel {
    padding: .75rem 1.25rem;
    background-color: rgb(180, 180, 180);
    border-radius: 10px;
    border: 1px solid transparent;
    margin-bottom: rem(30px);
    
    
    &.-correct {
        color:@success-color;
        background-color: @success-background-color;
        border-color: @success-border-color;
        
    }
    &.-wrong {
        color:@alert-color;
        background-color: @alert-background-color;
        border-color: @alert-border-color;
    }
}