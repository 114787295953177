[class^="icon-"], [class*=" icon-"] {
    height: 32px;
    width: 32px;
    display: inline-block;
    fill: currentColor;
    transition: transform 0.5s;

    &.-small {
        height: 20px;
        width: 20px;
    }

    &.-sub {
        vertical-align: sub;
    }

    &.-inverted {
        transform:rotate(-180deg);
    }

    &.-brand {
        color: @brand-color;
    }

    &.-rspacer {
        margin-right: 10px;
    }
}

.iconcontainer.-inverted > [class^="icon-"],
.iconcontainer.-inverted > [class*=" icon-"] {
    transform:rotate(-180deg);
}