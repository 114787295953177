/*
---
name: img.responsive
category: Layout/Image
tag:
 - responsive
compile: true
---

This class makes the image scaling to its max width.

```jade
img.responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
img.responsive {
	max-width: 100%;
	height: auto;
}
