.page-header {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #fff;
    
    .border-bottom {
        padding-bottom: 11px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        margin-left: 16px;
        margin-right: 16px;
    }

    .logo-section {
        width: 100%;
        height: 80px;
        background-color: @brand-color;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        line-height: 0px;
        
        .title {
            font-size: rem(33px);
            line-height: rem(50px);
            letter-spacing: rem(0.85px);
            font-weight: bold;
            color: #fff;
        }

        .logo{
            width: 96px;
            height: 25px;
            margin-left: 11px;
            margin-right: 50px;
            margin-top: 24px;
            margin-bottom: 24px;

            visibility: visible;
            opacity: 1;
        }

    }

    .main-navigation {
        display:inline-block;
    }
    .button-login {
        display:inline-block;
        margin-left: 32px+20px;
       vertical-align: text-top;
        
        a {
            font-size: rem(18px);
            line-height: rem(26px);
            color: @text-dark-color;
            text-transform: uppercase;
            font-weight: bold;

            .navi-text {
                vertical-align: text-bottom;
            }
            svg {
                vertical-align: text-top;
                color: @brand-color;
            }
        }
    }

    .button-login:hover a {
        color:@hover-color;
    }
    .button-login:active a {
        color: @dark-hover-color;
    }

    .sidebar-teacher {
        right:0;
    }

    .site-title {
        display:none;
    }
    .home-button {
        display:none;
    }


    &.-collabse {
        
        padding-top: 0px;

        .logo-section {
            visibility: hidden;
            height: 0px;
            transition: height 0.5s;
        }

       .title {
           display:none;
       }


       .site-title {
            position:relative;
            left: 75px;
            width:100%;
            white-space: nowrap;
            display:table-cell;
            font-size: 18px;
            vertical-align:middle;
       }
       .home-button {

            display:table-cell;
            
            padding-left: 50px;
            .icon{
                display:inline-block;
                height:26px;
                width: 26px;
                vertical-align:top;
            }

       }
    }
}


@media only screen and (max-width: @large-break-point) { 
    .page-header {
        &.-collabse {
            .site-title {
                display:none;
            }
        }

    }
}

@media only screen and (max-width: @small-break-point) { 
    .page-header {

        &.-collabse {
            .site-title {
                display:none;
            }
        }
        .logo-section {
             display:block;
            .logo{
                margin-top: 14px;
                margin-bottom: 9px;
                margin-left: 8px;
                
            }
            .title {
                font-size: 18px;
                 line-height: rem(29px);
                //letter-spacing: rem(0.57px);
                font-weight: bold;
                margin-left: 16px;
            }
        }
        .button-login {
            .navi-text {
                display:none;
            }
        }
        .button-teacher {
            width: 30px;
            .navi-text {
                display:none;
            }
        }
    }
 }
