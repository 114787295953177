.feedback-container {
   text-align:right;
    margin-bottom: 20px;
   .button {
       padding:0;
       cursor: pointer;
       &:focus {
           outline:none;
       }
   }
}