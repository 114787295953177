.button-filter{
    
    position:relative;
    color: #000;
    background-color: #F6F6F6;
    cursor: pointer;
    padding: rem(16px);
    border-bottom: 1px solid #fff;

    &:last-child {
        border: none;
    }

    &.-active {
        color: @brand-color;
        >.icon-checked {
            display: inline-block;
            color: @brand-color;
            position: absolute;
            right: rem(16px);
            top: 23px;
        }
    }

    &:hover {
        color: @hover-color;
    }

    &.-hidden {
        display:none;
    }
    >.icon-filter, >.icon-checked {
        display: none;
    }

    &.-toggle {
        color: #fff;
        background-color: @brand-color;
        
        >.icon-checked {
            display: none;
        }

        >.icon-filter {
            display: inline-block;
            margin-right: rem(16px);
        }
    }
}


/*
.button-filter {
    
    margin-bottom: 20px;
    padding: 10px;
    width: 132px;
    height: 44px;
    border-radius: 22px;
    background-color: @brand-color;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3);
    color: white;
    font-size: rem(16px);
    letter-spacing: rem(0.48px);
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.2s;

    &:hover {
        background-color: @hover-color;
    }

    &.-active{
        background-color: @dark-hover-color;
        box-shadow: 0 6px 6px 0 rgba(0,0,0,0.3);
    }
    &.-hidden {
        display:none;
    }
}

*/