.sidebar-teacher {
    
    font-size: 18px;


    &.-teacher {
        .container-teacher.sort-0 {
            .title {
                border-top: 5px solid @brand-color;
            }
            
        }
    }

    .label {
        font-size: 14px;
        display: inline-block;
    }

    .meta-space {
        margin-bottom: 64px;
    }

    
    background-color:#F6F6F6;
    box-shadow: -1px 1px 0 0 hsla(0, 0%, 0%, 0.25);
    padding-left: 27px;
    padding-right: 34px;

    width: 416px;
    //padding-top: 10px;
    position: fixed;
    right: 0px;
    //top: 174px;
    top: 133px;
    bottom: 0px;
    display:block;
    overflow-y: auto;
    z-index:10;

    &.-close {
        display:none;
    }

    &.-collabse {
        top: 55px;
        transition-timing-function: linear;
        transition: top 0.5s;
    }

}

@media only screen and (max-width: @small-break-point) { 
    .sidebar-teacher {
        top: 133px;
        padding-right:20px;
        width:343px;
    }
}