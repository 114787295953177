/*
---
name: .grid
category: Layout/Grid
tag:
 - component
 - responsive
compile: true
---

A simple grid system that wraps children nodes based on the viewport width.

## Simple side by side rendering

The easiest use case is to use simple `.col-1`, `.col-1-2`, `.col-1-3`, `.col-1-4` for a side-by-side rendering.
The default value for all child elements of a `.grid` is equivalent to `.col-1`, so for only one child it will span the full width.

```jade
div.grid
	div.col-1-2 
		div.aigis-debug col-1-2
	div.col-1-2
		div.aigis-debug col-1-2
```
```jade
div.grid
	div.col-1-4 
		div.aigis-debug col-1-4
	div.col-3-4
		div.aigis-debug col-3-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the `@grid-break-points`, `@grid-break-labels` variables.
The default values define two breakpoints with labels: `.medium-X-X` and `.large-X-X`.
So an example grid with two breakpoints looks like this:

```jade
div.grid
	div.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1 medium-1-2 large-1-4
	div.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1 medium-1-2 large-1-4
	div.col-1-2.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1-2 medium-1-2 large-1-4
	div.col-1-2.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1-2 medium-1-2 large-1-4
```

**Note:** The `col-1` classes are not present on the first two nodes, since this is the default behaviour.


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid.-reverse
	div.col-1-4
		div.aigis-debug First column in DOM
	div.col-1-4
		div.aigis-debug Second column in DOM
	div.col-1-4
		div.aigis-debug Third column in DOM
	div.col-1-4
		div.aigis-debug Fourth column in DOM
```
## No gutter

If you dont need a spacing/gutter between the grid columns you can use the modifier `.-nogutter`.

```jade
div.grid.-nogutter
	div.col-1-4
		div.aigis-debug First column in DOM
	div.col-1-4
		div.aigis-debug Second column in DOM
	div.col-1-4
		div.aigis-debug Third column in DOM
	div.col-1-4
		div.aigis-debug Fourth column in DOM
```
*/

.grid {

	margin: 0 -@grid-gutter/2;

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	> * {
		float: left;
		position: relative;
		width:100%;
		padding: 0 @grid-gutter/2;
	}

	&.-reverse > * {
		float:right;
	}


	&.-nogutter {
		margin: 0;

		> * {
			padding: 0;
		}
	}


	.col-1 {
		width: 100%;
	}
	.col-1-2 {
		width: 100%/2;
	}
	.col-1-3 {
		width: 100%/3*1;
	}
	.col-2-3 {
		width: 100%/3*2;
	}
	.col-1-4 {
		width: 100%/4*1;
	}
	.col-3-4 {
		width: 100%/4*3;
	}

	/**
	 * Generate all media breakpoints based on the @grid-break-points, @grid-break-labels variables.
	 */
	.for( @grid-break-points, {

		@label: extract( @grid-break-labels, @i );

		@media all and ( min-width: @value ) {

			> .@{label}-1 {
				width: 100%;
			}

			> .@{label}-1-2 {
				width: 100%/2;
			}

			> .@{label}-1-3 {
				width: 100%/3*1;
			}

			> .@{label}-2-3 {
				width: 100%/3*2;
			}

			> .@{label}-1-4 {
				width: 100%/4*1;
			}

			> .@{label}-3-4 {
				width: 100%/4*3;
			}
		}
	});
}