.module-label {
    color: @text-light-color;
    margin-bottom: 10px;
    font-size: 16px;

    &.-half {
        width: 50%;
        display: inline-block;
    }
    
    .icon {
        color: @brand-color;
        margin-right: 9px;
    }
    
}