.container-questions {
    margin-bottom: rem(90px);
    
    .main-title {
        margin-bottom: rem(60px);
        text-align: center;
        font-weight: bold;
        color: black;
    }
    .number {
        margin-bottom: rem(46px);
        color: @brand-color;
        width: 100%;
        text-align: center;
        border-bottom: 6px solid @brand-color;
        line-height: 0.05em;
        font-size: rem(48px);

        span {
            padding:0 20px;
            background-color: #fff;
            font-weight: bold;
        }
    }
}

@media only screen and (max-width: @small-break-point) { 
    .container-questions {
        margin-bottom: rem(32px);
        >.number {
            margin-bottom: rem(20px);
            font-size: rem(26px);
            //line-height: rem(34px);
            //line-height: 0.05em;
        }
    }

}
