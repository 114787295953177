.button-export {
    height: 32px;
    width: 98px;

    color: @brand-color;
    display: inline-block;
    border-radius: 16px;
    border: 2px solid @brand-color;

    font-size: rem(14px);
    text-align:center;
    line-height: rem(28px);

    transition: all 0.1s;

    &:hover {
        border-color: @hover-color;
        background-color: @hover-color;
        color: #fff;
    }

    &:active {
        border-color: @brand-color;
        background-color: @brand-color;
        color: #ffffff;
    }
}