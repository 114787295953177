.page-layout {

    max-width: 100%;
    margin:auto;


    & > .content {
        padding-top: 162px;

        &.-sidebar {
            padding-right: 400px;
        }


    }

}

@media only screen and (max-width: @small-break-point) { 
    .page-layout {
    
        & > .content {
            padding-top: 0;
            margin-top: 152px;
    
            &.-sidebar {
                padding-right: 0px;
                padding-top:0px;
            }
        }
    }

}