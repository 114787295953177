.common-page {
    margin-top: rem(85px);

    h1, h2, h3 {
        font-weight: bold;
        color: black;
        font-size: rem(48px);    
        line-height: rem(58px);
        text-align: center;
        margin-bottom: rem(60px);
    }

    >.paragraph-element {
        margin-bottom: rem(25px);
    }
    >.image-element {
        margin-bottom: rem(25px);
    }
    >.delimiter-line {
        margin: rem(50px) 0;
    }
}


@media only screen and (max-width: @small-break-point) {
    
    .common-page {
        margin-top: rem(0px);
        &.-nocover {
            margin-top: rem(180px);
        }
        h1, h2, h3 {
            font-size: rem(26px);    
            line-height: rem(34px);
        }
        
        >.title-element {
            margin-bottom: rem(40px);
        }
        >.paragraph-element {
            margin-bottom: rem(15px);
        }
        >.image-element {
            margin-bottom: rem(15px);
        }

        >.delimiter-line {
            margin:  rem(30px) 0;
        }
    }

}