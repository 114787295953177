.page-fluid {
    padding: 0 rem(16px);
    max-width: rem(610px+16px+16px);
}


@media only screen and (max-width: @small-break-point) { 
    .content & .page-fluid {
        padding: 0 0;
    }
}