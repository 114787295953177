.image-element {

    &.-small {
        max-width: 50%;
    }
}

@media only screen and (max-width: @small-break-point) {
    .image-element {
        &.-small {
            max-width: 100%;
        }
    }
}


