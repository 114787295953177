.chapter-cover {
    height: 450px;
    //margin-top: 10px;
    margin-bottom: 85px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    >.title {
        text-transform: uppercase;
        width: 940px;
        color: rgba(255,255,255,1);
        font-size: rem(64px);
        font-weight: demi;
        line-height: rem(74px);
        text-align: center;
        letter-spacing: rem(1.9px);
        text-shadow: 0.1px 0.1px 16px #6a6a6a73;
    }
}

//test
@media only screen and (max-width: @small-break-point) { 
    .chapter-cover {
        margin-bottom: rem(49px);
        height: 340px;
        >.title {
            font-size: rem(30px);
            line-height: rem(40px);
        }
    }
}
