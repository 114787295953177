/*
---
name: .row
category: Layout/Row
tag:
 - component
compile: true
---

This styling allow to place containers in a row. They will **not** break as the `.grid` but resize them accordingly.
Some benefits of the `.row` approach is the easy vertical centering and the usage of the `.col-auto` class.

## Basic row with vertical centering

You can use the sizing classes `.col-X-X` to define the width of a column as the following example shows. 

```jade
div.row
	div.col-1-4.aigis-debug col-1-4 with a lot of text that spans the column.
	div.col-3-4.aigis-debug col-3-4 content.
```

## Fixed row

There is a `.-fixed` modifier where each column has the same width.

```jade
div.row.-fixed
	div.aigis-debug evenly spreadout column
	div.aigis-debug evenly spreadout column with more content
	div.aigis-debug evenly spreadout column
	div.aigis-debug evenly spreadout column with much more content than the other
	div.aigis-debug.vcenter evenly spreadout column with .vcenter
```


## Auto sized column width and vcenter

There is a special `.col-auto` class that resizes the column to the size of its content.
This is usefull for example if you have a fixed size logo and the rest of the header has to resize.

```jade
div.row.-center
	div.col-auto.aigis-debug
		img(src="http://placehold.it/100x100")
	div.col-1.aigis-debug The rest
	div.col-auto.aigis-debug
		img(src="http://placehold.it/200x100")
```
*/
.row {
	display: table;
	width: 100%;

	&.-fixed {
    	table-layout: fixed;
	}

	&.-center {
		> * {
			vertical-align: middle;
		}
	}

	&.-bottom {
		> * {
			vertical-align: bottom;
		}
	}

	&.-padded {
		> * + * {
			padding-left: @row-gutter;
		}
	}
	
	> * {
		display: table-cell;
		
		&.col-1 {
			width: 100%;
		}
		&.col-1-2,
		&.col-2-4 {
			width: 100%/2;
		}
		&.col-1-3 {
			width: 100%/3*1;
		}
		&.col-2-3 {
			width: 100%/3*2;
		}
		&.col-1-4 {
			width: 100%/4*1;
		}
		&.col-3-4 {
			width: 100%/4*3;
		}
		
		&.col-auto {
			width:auto;
			white-space: nowrap;
		}
	}
}


