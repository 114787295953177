/*
---
name: .stretch
category: Shared/Helpers
tag:
 - helpers
---

`.stretch` Define an element which stretches to the size of its parent

```html
<div class="rel" style="height: 55px;">
   <div class="stretch styleguide-grid-unit"> I'm stretched to the size of my parent</div>
</div>
```
*/
.stretch {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/*
---
name: .abs
category: Shared/Helpers
tag:
 - helpers
---

`.abs` Set the element position to absolute
*/
.abs {
	position: absolute;
}


/*
---
name: .rel
category: Shared/Helpers
tag:
 - helpers
---

`.rel` Set the element position to relative
*/
.rel {
	position: relative;
}


/*
---
name: .vcenter
category: Shared/Helpers
tag:
 - helpers
---

`.vcenter` Sets vertical-align to middle
*/
.vcenter {
	vertical-align: middle;
}


/*
---
name: .hcenter
category: Shared/Helpers
tag:
 - helpers
---

`.hcenter` Sets text-align to center
*/
.hcenter {
	text-align: center;
}


/*
---
name: .floatleft
category: Shared/Helpers
tag:
 - helpers
---

`.floatleft` Sets float to left
*/
.floatleft {
	float: left;
}

/*
---
name: .floatright
category: Shared/Helpers
tag:
 - helpers
---

`.floatright` Sets float to right
*/
.floatright {
	float: right;
}

/*
---
name: .hidden
category: Shared/Helpers
tag:
 - helpers
---

`.hidden` Sets display to none.
*/
.hidden {
	display: none;
}