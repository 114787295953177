.sidebar-login {

    background-color:@brand-color;
    margin-left: -27px;
    margin-right: -18px;
    // margin-bottom: 10px;
    
    //padding-top: 10px;
    padding-left: 27px;
    padding-right: 18px;
    
     a {
        color:#fff;
        padding: 10px 0;
        display: inline-block;
    }

    .icon {
        width: 22px;
        height: 22px;
        margin-right: 16px;
        vertical-align: sub;
        

        &.icon-arrow-down {
            color: inherit;
            transform:rotate(-90deg);
            position: relative;
            top:0;
            right:-40px;
        }
    }
    .title {
        display: inline-block;
    }

    &:hover {
        background-color: @hover-color;
        cursor:pointer;
    }

}

@media only screen and (max-width: @small-break-point) { 
    .sidebar-login {
        .icon {
            &.icon-arrow-down {
                right: -20px;
            }
        }
    }
}
