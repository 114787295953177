.box-chapter {
    //width: 293px;
    //height: 420px;
    margin-bottom: 90px;

    .mandatory {
        min-height: 34px;
    }

    .image {
        padding-bottom: 13px;
        border-bottom: 3px solid @brand-color;
        max-height: 203px;
        //width: 292px;
        //height: 180px;

    }
    .title {
        margin: 14px 0;
        color: @text-dark-color;
        height: 80px;
        overflow: hidden;
        &:before {
            content: '\00ab'
        }
        &:after {
            content: "\00bb"
        }
    }

    .link-container:hover {
        .image {
            opacity:0.8;
        }
        .title {
            color: @brand-color;
        }
    }

    .class > svg, .duration > svg, .label > svg {
        margin-right: 9px;
        color: @brand-color;
    }

    .class, .duration {
        display: inline-block;
        width: 50%;
    }

    .label {
        margin-top: 12px;
        margin-bottom: 32px;
    }
    
    .export {
        //text-align: right;
        >.button-export {
            margin-right: 10px;
        }
        >.button-export:last-child {
            margin-right: 0px;
        }

    }
}

@media only screen and (max-width: @large-break-point) { 
    .box-chapter {
        .image {
            max-height: 307px;
        }
    }
}



@media only screen and (max-width: @small-break-point) { 
    .box-chapter {
        margin-bottom: 60px;

        .image {
            max-height: 380px;
        }
        
        >.title {
            height: 60px;
        }
    }
}


