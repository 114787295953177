.button-teacher {
    
    cursor: pointer;
    font-size: 18px;
    text-transform: uppercase;
    width: 400px;
    text-align:right;
    display:block;
    font-weight:bold;

    &:hover {
        color: @hover-color;
    }
    
    .icon {
        color: @brand-color;
        height: 26px;
        width: 26px;
        vertical-align: text-top;
        // &:hover {
            //color: @hover-color;
        // }
    }

    &.-close {
        text-align: left;
        color: #000;
        .icon {
            transform: rotate(-180deg);
        }
        &:hover {
            color: @hover-color;
        }
    }
}