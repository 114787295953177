.page-overview {

    max-width: rem(940px);

    .introduction {
        margin-bottom: rem(80px);
        //width: 940px;
        color: #000000;
        font-size: rem(38px);
        font-weight: 500;
        line-height: 50px;
    }
}


@media only screen and (max-width: @small-break-point) { 
    .page-overview {
        >.introduction {
            font-size: rem(26px);
            line-height: rem(34px);
        }
    }
}

